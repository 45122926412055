import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import { ocorrenciaStore } from '@/store';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('timeValidationRules', {
    validate: (value) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return regex.test(value);
    },
    getMessage: (field) => 'O campo de hora deve estar no formato HH:MM',
});
let CriarOcorrenciaComponent = class CriarOcorrenciaComponent extends Vue {
    constructor() {
        super(...arguments);
        this.parceriaSelected = null;
        this.origemSelected = null;
        this.ocorrenciaObservacao = '';
        this.desejaGerarReuniaoSelect = null;
        this.ocorrenciaEntidadeSelected = null;
        this.ocorrenciaTarefaData = null;
        this.ocorrenciaTarefaHora = null;
        this.tarefaDescricao = '';
        this.ocorrenciaUploadFiles = [];
        this.tarefas = [];
        this.ocorrenciaReuniaoSelected = null;
        this.loadingGerandoOcorrencia = false;
        this.desejaGerarTarefaSelected = null;
        this.ocorrenciaOrigemOptions = [
            { id: 1, nome: 'Ligação' },
            { id: 2, nome: 'Mensagem' },
            { id: 3, nome: 'Vídeo' },
            { id: 4, nome: 'Presencial' },
            { id: 5, nome: 'Informação' },
        ];
        this.tarefaHeaders = [
            { text: 'Usuário responsável', value: 'responsavel_nome' },
            { text: 'Tarefa agendada para', value: 'data' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Reunião', value: 'reuniao_tipo_id' },
            { text: '', value: 'actions' },
        ];
        this.activePanel = 0;
    }
    get ocorrenciaReuniaoOptions() {
        return [
            { id: 1, nome: 'Outros' },
            { id: 2, nome: 'Reunião financeira' },
            { id: 3, nome: 'Reunião pedagógica' },
            { id: 4, nome: 'Reunião de rendimento' },
            { id: 5, nome: 'Reunião troca de livro' },
            { id: 6, nome: 'Reunião rematrícula' },
            { id: 7, nome: 'Reunião de negociação de externo' },
            { id: 8, nome: 'Reunião de execução de externo' },
            { id: 9, nome: 'Reunião de 30 dias (supervisão)' },
        ];
    }
    closeDialog() {
        this.$emit('on-cancel');
    }
    async adicionarTarefa() {
        let entidadeIsValid = true;
        entidadeIsValid =
            // @ts-ignore
            await this.$refs.searchEntidadeComponent.$refs.observer.validate();
        // @ts-ignore
        const isValid = await this.$refs.observer.validate();
        if (!isValid || !entidadeIsValid) {
            return;
        }
        this.tarefas.push({
            responsavel_id: this.ocorrenciaEntidadeSelected.id,
            responsavel_nome: this.ocorrenciaEntidadeSelected.nome,
            data: this.ocorrenciaTarefaData,
            hora: this.ocorrenciaTarefaHora,
            descricao: this.tarefaDescricao,
            reuniao_tipo_id: this.ocorrenciaReuniaoSelected,
            status_id: 0,
        });
        this.resetTarefaFields();
    }
    resetData() {
        this.parceriaSelected = null;
        this.origemSelected = [];
        this.ocorrenciaObservacao = '';
        this.desejaGerarTarefaSelected = null;
        this.ocorrenciaEntidadeSelected = '';
        this.ocorrenciaTarefaData = '';
        this.ocorrenciaTarefaHora = '';
        this.ocorrenciaReuniaoSelected = null;
        this.tarefas = [];
    }
    async salvarOcorrencia() {
        this.loadingGerandoOcorrencia = true;
        const obj = {
            origem_id: this.parceriaSelected,
            observacao: this.ocorrenciaObservacao,
            objecao_id: null,
            entidade_id: this.propEntidadeId ? this.propEntidadeId : this.parceriaSelected,
            entidade_tipo: this.propEntidadeTipo,
            tarefas: this.tarefas,
        };
        if (this.desejaGerarTarefaSelected === 1) {
            let data_hora_tarefa = this.ocorrenciaTarefaData;
            data_hora_tarefa =
                data_hora_tarefa + 'T' + this.ocorrenciaTarefaHora + ':00-03:00';
            const horario_brasilia = new Date(data_hora_tarefa);
            data_hora_tarefa = horario_brasilia;
        }
        await ocorrenciaStore.createOcorrencia({
            data: obj,
            files: this.ocorrenciaUploadFiles,
        });
        this.resetData();
        this.closeDialog();
        this.loadingGerandoOcorrencia = false;
    }
    nextStep(step) {
        this.activePanel = step;
    }
    resetTarefaFields() {
        this.desejaGerarReuniaoSelect = null;
        this.ocorrenciaTarefaData = null;
        this.ocorrenciaTarefaHora = null;
        this.tarefaDescricao = '';
        this.ocorrenciaReuniaoSelected = null;
    }
};
__decorate([
    Prop()
], CriarOcorrenciaComponent.prototype, "propEntidadeId", void 0);
__decorate([
    Prop()
], CriarOcorrenciaComponent.prototype, "propEntidadeTipo", void 0);
__decorate([
    Prop({ type: Array, required: true })
], CriarOcorrenciaComponent.prototype, "propParceriaOptions", void 0);
CriarOcorrenciaComponent = __decorate([
    Component({
        components: {
            UploadFilesComponent,
            SearchEntidadeComponent,
            DateFieldComponent,
            ValidationObserver,
            ValidationProvider,
        },
    })
], CriarOcorrenciaComponent);
export default CriarOcorrenciaComponent;
